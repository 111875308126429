import {
    Dropdown,
    Menu,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import Plans from './Plans';
import { Plan } from '../../../../interfaces/plan';

interface Props {
    plan: Plan | null;
    changePlan: (plan: Plan) => void;
}

const { Text } = Typography;

const PlanSelector = ({ plan, changePlan }: Props) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleActive = () => setVisible(!visible);

    const ITEMS = [
        {
            key: '1',
            label: (
                <Plans
                    changePlan={changePlan}
                    handleActive={handleActive}
                />
            ),
        },
    ];

    return (
        <Dropdown
            trigger={['click']}
            placement="bottomRight"
            open={visible}
            overlay={(
                <Menu
                    items={ITEMS}
                />
            )}
        >
            <Space size={4} align="center" onClick={handleActive}>
                <Tooltip title={plan?.name || ''}>
                    <Text>
                        { plan && plan?.name.length > 20 ? `${plan?.name.slice(0, 20)}...` : (plan?.name || 'Unassigned')}
                    </Text>
                </Tooltip>
                <AiOutlineDown
                    size={16}
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                />
            </Space>
        </Dropdown>
    );
};

export default PlanSelector;
